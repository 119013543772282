:root {
  --romaine-50: #EFFBF7;
  --romaine-100: #E6F9F2;
  --romaine-200: #CAF2E3;
  --romaine-250: #ADEBD4;
  --romaine-300: #6FDCB3;
  --romaine-350: #32CD93;
  --romaine-400: #2CB783;
  --romaine-500: #149566;
  --romaine-600: #1F7C5A;
  --romaine-700: #125E43;
  --romaine-800: #224F3F;
  --romaine-900: #15372B;

  --tomato-350: #ff9992;
  --tomato-400: #ff7e75;
  --tomato-500: #fe493d;
  --tomato-600: #ee3123;
  --tomato-700: #db170a;
  --tomato-800: #8c0a03;
  --tomato-900: #3a0400;

  --blueberry-50: #fafdfd;
  --blueberry-100: #f0f6f8;
  --blueberry-200: #d6e3e6;
  --blueberry-300: #a7c7cf;
  --blueberry-350: #91b5be;
  --blueberry-400: #7b9fa8;
  --blueberry-500: #5c818a;
  --blueberry-600: #436068;
  --blueberry-700: #34464b;
  --blueberry-800: #222f32;
  --blueberry-900: #131c1f;

  --paprika-250: #ffe200;
  --paprika-300: #ffbf00;
  --paprika-350: #ff9900;
  --paprika-400: #fd8700;
  --paprika-500: #f56200;

  --grape-50: #f4feff;
  --grape-100: #e6fafd;
  --grape-200: #c1eaee;
  --grape-250: #b3e1e6;
  --grape-300: #8ad0d6;
  --grape-350: #72bec5;
  --grape-400: #5aa9b0;
  --grape-500: #318b93;
  --grape-600: #1c6c73;
  --grape-700: #115055;
  --grape-800: #0a3538;
  --grape-900: #001c1f;

  --sky-50: #f6fcfe;
  --sky-100: #e8f8fe;
  --sky-200: #bfebfa;
  --sky-250: #a6e2f8;
  --sky-300: #76d5f4;
  --sky-350: #4acdf3;
  --sky-400: #00b9ec;
  --sky-500: #009ede;
  --sky-600: #0085bc;
  --sky-700: #01638c;
  --sky-800: #044966;
  --sky-900: #003247;

  --white-900: #fff;
  --black-50: #fbfbfb;
  --black-100: #f5f5f5;
  --black-200: #e1e1e1;
  --black-250: #d8d8d8;
  --black-300: #c0c0c0;
  --black-350: #acacac;
  --black-400: #999999;
  --black-500: #7b7b7b;
  --black-600: #5b5c5c;
  --black-700: #434444;
  --black-800: #2b2b2b;
  --black-900: #0b0c0c;

  --rainist-500: #109cd8;
  --event-500: #3bccc5;
  --facebook-500: #4267b2;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  --desktop-width: 946px;

  --border-radius-light: 2px;
  --border-radius-regular: 4px;
  --border-radius-bold: 6px;
  --border-radius-black: 8px;

  --mobile-s: 320px;
  --mobile-m: 375px;
  --mobile-l: 425px;
  --tablet: 768px;

  --env-safe-area-inset-top: env(safe-area-inset-top);
  --env-safe-area-inset-bottom: env(safe-area-inset-bottom);
}

@custom-media --mobile-s (max-width: 320px);
@custom-media --mobile-m (max-width: 375px);
@custom-media --mobile-l (max-width: 425px);
@custom-media --tablet (max-width: 768px);
